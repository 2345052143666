
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PageTitle',
})
export default class PageTitle extends Vue {
  @Prop({ type: String }) title!: string;
  @Prop({ type: String }) subTitle!: string;
}
